// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Pretendard";
}

.wrap {
  min-height: calc(100vh - 100px);
  position: relative;
  width: 100%;
  padding-bottom: 100px;
}

.header {
  margin: 0 auto;
  width: 75%;
}

.header-title {
  padding-top: 5%;
  padding-bottom: 1%;
  border-bottom: 1px solid#D6D6D6;
}

.page-title {
  font-size: 28px;
  font-weight: bolder;
  color: #2d2d2d;
  font-weight: 600;
}

.page-subTitle {
  padding-top: 5px;
  font-size: 16px;
  color: #7d7d7d;
  font-weight: 400;
}

/* 개인정보처리방침 */
footer {
  width: 100%;
  height: 80px;
  bottom: 0;
  position: absolute;
}

.footer-all {
  width: 100%;
  height: 80px;
  background-color: #e3e3e3;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer-title {
  color: #4b4b4b;
  font-weight: 700;
  cursor: pointer;
}

.footer-copy {
  color: #979797;
  font-weight: 400;
}

@media (max-width: 768px) {
  .header {
    width: 95%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA,aAAa;AACb;EACE,WAAW;EACX,YAAY;EACZ,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":["* {\r\n  margin: 0;\r\n  padding: 0;\r\n  list-style: none;\r\n  font-family: \"Pretendard\";\r\n}\r\n\r\n.wrap {\r\n  min-height: calc(100vh - 100px);\r\n  position: relative;\r\n  width: 100%;\r\n  padding-bottom: 100px;\r\n}\r\n\r\n.header {\r\n  margin: 0 auto;\r\n  width: 75%;\r\n}\r\n\r\n.header-title {\r\n  padding-top: 5%;\r\n  padding-bottom: 1%;\r\n  border-bottom: 1px solid#D6D6D6;\r\n}\r\n\r\n.page-title {\r\n  font-size: 28px;\r\n  font-weight: bolder;\r\n  color: #2d2d2d;\r\n  font-weight: 600;\r\n}\r\n\r\n.page-subTitle {\r\n  padding-top: 5px;\r\n  font-size: 16px;\r\n  color: #7d7d7d;\r\n  font-weight: 400;\r\n}\r\n\r\n/* 개인정보처리방침 */\r\nfooter {\r\n  width: 100%;\r\n  height: 80px;\r\n  bottom: 0;\r\n  position: absolute;\r\n}\r\n\r\n.footer-all {\r\n  width: 100%;\r\n  height: 80px;\r\n  background-color: #e3e3e3;\r\n  text-align: center;\r\n  font-size: 16px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  flex-direction: column;\r\n}\r\n\r\n.footer-title {\r\n  color: #4b4b4b;\r\n  font-weight: 700;\r\n  cursor: pointer;\r\n}\r\n\r\n.footer-copy {\r\n  color: #979797;\r\n  font-weight: 400;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .header {\r\n    width: 95%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
