import React from 'react'

import '../components/Layout.css';
import '../app/Clear.css';

import Finish from '../assets/png/finish.png';
import Home from '../assets/png/home.png';

import { Link } from "react-router-dom";
import Button from '../components/Button';
import Header from '../components/Header';
import Footer from '../components/Footer';

const visitSiteIndex = sessionStorage.getItem('VisitSite') // 세션에 저장된 VisitSite값

// URL 쿼리 파라미터에서 'visitsite' 값을 추출
const queryParams = new URLSearchParams(location.search);
const queryvisitSiteIndex = queryParams.get('visitsite'); // 쿼리 파라미터가 없는 경우 기본값으로 '0'을 사용

export default function Clear() {
      return (
            <div className='wrap'>
                  <Header pageTitle={"방문예약"} pageSubTitle={"방문예약을 위해 아래 항목을 입력해 주세요."} />
                  <div className='clearAll'>
                        <img src={Finish} alt='완료' />
                        <div className='clearText'>
                              <p>방문 예약 신청이 완료되었습니다 :)</p>
                              <p>방문신청 조회 페이지에서 신청 내역을 확인해 보세요</p>
                        </div>
                        <Link to={`/home?visitsite=${queryvisitSiteIndex}`}>
                              <div className='btn-blue'>
                                    <Button btn={"홈으로"} btnIcon={Home} />
                              </div>
                        </Link>
                  </div>
                  {/* 개인정보처리방침 */}
                  <div>
                        <Footer />
                  </div>
            </div>
      )
}
