import React from 'react'

export default function Header({pageTitle, pageSubTitle}) {
      return (
            <div>
                  <div className='header'>
                        <div className='header-title'>
                              <p className='page-title'>{pageTitle}</p>
                              <p className='page-subTitle'>{pageSubTitle}</p>
                        </div>
                  </div>
            </div>
      )
}
