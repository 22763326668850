// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clearAll {
      text-align: center;
      padding-top: 8%;
}

.clearText {
      color: #2D2D2D;
      font-size: 18px;
      font-weight: 600;
      padding-top: 1%;
      padding-bottom: 10%;
}`, "",{"version":3,"sources":["webpack://./src/app/Clear.css"],"names":[],"mappings":"AAAA;MACM,kBAAkB;MAClB,eAAe;AACrB;;AAEA;MACM,cAAc;MACd,eAAe;MACf,gBAAgB;MAChB,eAAe;MACf,mBAAmB;AACzB","sourcesContent":[".clearAll {\r\n      text-align: center;\r\n      padding-top: 8%;\r\n}\r\n\r\n.clearText {\r\n      color: #2D2D2D;\r\n      font-size: 18px;\r\n      font-weight: 600;\r\n      padding-top: 1%;\r\n      padding-bottom: 10%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
