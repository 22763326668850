
import React, { useState, useEffect } from 'react';
import '../components/Layout.css';
import '../app/Clear.css';
import { useLocation } from 'react-router-dom';

import CryptoJS from 'crypto-js';
import iconv from 'iconv-lite';

import Finish from '../assets/png/finish.png';
import Home from '../assets/png/home.png';
import { Buffer } from 'buffer';
import { Link } from "react-router-dom";
import Button from '../components/Button';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function NiceResult() {
    const [resultcode, setResultCode] = useState(null); // resultcode 상태 추가
    const [mobileNo, setMobileNo] = useState(null); // mobileNo 상태 추가
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // 본인인증 결과값 추출  
    const token_version_id = queryParams.get('token_version_id');
    const encData = queryParams.get('enc_data');
    const integrity_value = queryParams.get('integrity_value');

    const NiceTokenData = async () => {
        // POST 요청을 위한 설정
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                token_version_id: token_version_id,
                enc_data: encData,
                integrity_value: integrity_value
            }),
            credentials: 'include'
        };

        await fetch("/api/Nice", requestOptions)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`Server responded with ${res.status}`);
                }
                return res.text(); // 결과값을 텍스트로 받아옴.
            })
            .then((data) => {
 
                console.log("호출 결과:", data);

                // 정규식을 사용하여 "resultcode":"0000" 패턴에서 0000 값을 추출
                const matchResultCode = data.match(/"resultcode":"(\d+)"/);
                console.log(matchResultCode);
                if (matchResultCode && matchResultCode[1]) {
                    setResultCode(matchResultCode[1]); // 상태에 저장
                    console.log("resultcode 값:", matchResultCode[1]);
                }

                // 정규식을 사용하여 "mobileno":"010xxxxxxx" 패턴에서 전화번호 값을 추출
                const matchMobileNo = data.match(/"mobileno":"(\d+)"/);
                console.log(matchMobileNo);
                if (matchMobileNo && matchMobileNo[1]) {
                    setMobileNo(matchMobileNo[1]); // 상태에 저장
                    console.log("mobileno 값:", matchMobileNo[1]);
                }
            })
            .catch((error) => {
                console.error("API 호출 중 에러 발생:", error);
            });
    }

    const handleCloseWindow = () => {
        window.close();
        console.log("닫힘")
    }

    useEffect(() => {
        NiceTokenData();
    }, [encData]);

    useEffect(() => {
        if (mobileNo) {
            window.opener.postMessage({mobileNo} , 'https://visitor.schoolvisit.co.kr/reservation');
        }
    }, [mobileNo]);
    
    return ( 
        <div className='wrap'>
            <Header pageTitle={"방문예약"} pageSubTitle={"방문예약을 위해 아래 항목을 입력해 주세요."} />
            <div className='clearAll'>
                <img src={Finish} alt='완료' />
                <div className='clearText'>
                    <p>NICE 본인인증이 완료되었습니다 :)</p>
                    <p>방문신청 조회 페이지에서 신청 내역을 확인해 보세요</p>
                    {/* <p>enc_data 값: {encData}</p> enc_data 값을 출력 */}
                </div>
                <div className='btn-blue' onClick={handleCloseWindow}>
                    <Button btn={"확인"} btnIcon={Home} />
                </div>
            </div>
            {/* 개인정보처리방침 */}
            <div>
                <Footer />
            </div>
        </div>
    )
}