import "./Reapply.css";
import React, { useState, useEffect } from "react";
import Visitor from "../assets/png/person.png";
import Lock from "../assets/png/lock.png";
import Pin from "../assets/png/pin.png";
import Refusal from "../assets/png/refusal.png";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useForm } from "react-hook-form";
import { apiGetVisitSiteName } from "../api";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

export default function Reservation() {
  const [agreeState, setAgreeState] = useState(false); // agreeState를 useState로 변경

  const location = useLocation();
  // URL 쿼리 파라미터에서 'visitsite' 값을 추출
  const queryParams = new URLSearchParams(location.search);
  const queryvisitSiteIndex = queryParams.get('visitsite'); // 쿼리 파라미터 추출

  const visitSiteIndex = sessionStorage.getItem('VisitSite') // 세션에 저장된 VisitSite값

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    reset,
  } = useForm({ mode: "onChange" });
  const navigate = useNavigate();
  const currentDate = new Date();
  // const [currentDate, setCurrentDate] = useState(new Date()); // 현재 날짜/시간 상태 추가

  const apiUrl = `/api/Agreement?visitSiteIndex=${queryvisitSiteIndex}`;
  const [indexCount, setIndexCount] = useState(0); // indexCount 상태 추가
  const [info, setInfo] = useState();

  useEffect(() => {
    const temp = localStorage.getItem("schoolinfo");
    const tempData = JSON.parse(temp);
    setInfo(tempData);
  }, []);

  useEffect(() => {
    reset(info);
  }, [info]);

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => {
        if (response.ok) {
          // 성공적인 응답 처리
          // 응답 데이터를 JSON으로 파싱하여 반환
          return response.json();
        } else {
          // 오류 응답 처리
          console.error("API 요청에 실패했습니다.");
          throw new Error("API 요청에 실패했습니다.");
        }
      })
      .then((data) => {
        // 응답 데이터를 사용하여 원하는 작업 수행
        // console.log("API 응답 데이터:", data);

        // agreements 배열의 길이(index의 갯수)를 추출하고 indexCount 상태 업데이트
        const newIndexCount = data.agreements.length;
        setIndexCount(newIndexCount);

        // 데이터를 변수에 저장하거나 다른 처리를 수행할 수 있음
      })
      .catch((error) => {
        // 오류 처리
        console.error("네트워크 오류 또는 처리 오류:", error);
      });
  }, []); // 빈 배열을 두어 한 번만 호출되도록 설정

  const onSubmit = (data) => {
    console.log(data);
    const tempDate = data.date + "T" + data.time;
    const selectedDate = new Date(tempDate); // 사용자가 선택한 방문  일시

    if (selectedDate <= currentDate) {
      // 선택한 일시가 현재 시간보다 이전이면 오류 처리
      alert("오늘 이후의 시간을 선택해주세요.");
    } else if(data.firstPassword != data.lastPassword){
      alert("비밀번호를 다시 한번 확인해주세요.");
    }
      else if (resultPhonenum == null && authtype === 1) {
      alert("본인인증을 진행 해주세요.");
      console.log(resultPhonenum, authtype);
    } else if (authtype === 0 || authtype === 1) {
      // authtype 값이 0(SMS) 이거나 1(NICE) 경우만 조건문 실행
      console.log("조건3 만족");
      if (!agreeState && authtype === 0) {
        // 본인 인증이 실패한 경우
        alert("본인 인증을 하세요.");
      } else if (authCode === "" && authtype === 0) {
        // 인증 번호를 입력하지 않은 경우
        alert("발급받은 인증 번호를 입력해주세요.");
      }
      // 모든 조건을 통과한 경우
      const selectedDateUTC = new Date(
        selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60 * 1000
      );

      const currentISODate = new Date().toISOString();



      /* POST 요청(방문 등록) 을 위한 데이터 구성 */
      /* data 객체를 이용하여 요청 데이터 구성 */

      // authtype 값에 따라 tel 값을 설정
      const telValue = authtype === 1 ? resultPhonenum : data.tel;

      const requestData = {
        visitSiteIndex: queryvisitSiteIndex, // 세션에 저장된 index 값
        state: 0, // 0 이라고 작성하면 기본 값이 승인대기 상태임
        resevationDate: selectedDateUTC.toISOString(),
        password: data.firstPassword,
        memo: data.memo,

        visitors: [
          {
            visitorIndex: 0,
            name: data.name ? data.name.trim() : '', // 사용자가 입력한 방문객명에서 공백 제거
            tel: telValue, // 사용자가 입력한 전화번호
            type: 1, // 1은 승인 대기 상태임
            code: authCode, // 인증 코드 변수 (위에서 설정한 변수)
            carNumber: data.carNum || "", // 사용자가 입력한 차량번호, 값이 없을 경우 빈 문자열
            purposeOfVisit: data.visitPurpose, // 사용자가 선택한 방문목적
            placeToVisit: data.visitPlace + " " + data.visitPlace2, // 사용자가 선택한 방문지
            regDate: currentISODate, // 현재 날짜와 시간으로 변경
          },
        ],
        // 담당자의 타입을 지정해주는 부분임 기본값들만 채움 (사용자가 담당자의 타입을 선택하여 신청 할수없기때문임)
        managers: [
          {
            managerIndex: -1,
            accountIndex: -1,
            name: "",
            position: "",
            auth: 0,
            state: 0,
            memo: "",
          },
        ],
        // 개인정보 동의여부를 묻는 데이터 기본값 True
        agrees: [
          {
            agreementIndex: indexCount,
            isAgree: true,
          },
        ],
      };
      // POST 요청 보내기
      fetch("/api/VisitReservation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => response.json())
        .then((response) => {
          // 요청 완료 후의 처리 로직 작성
          // console.log("POST 완료 결과:", response);
          navigate("/clear"); // 필요한 경우 페이지 이동 등의 작업 수행
        })
        .catch((error) => {
          console.error("POST 요청 오류:", error);
        });
      navigate("/clear");

      localStorage.setItem(
        "schoolinfo",
        JSON.stringify({
          sessionName: data.name,
          sessionCarNum: data.carNum,
          sessionTel: data.tel,
        })
      );
    }
  };

  // 인증
  const [test, setTest] = useState("");
  const [tel, setTel] = useState("");
  const [authCode, setAuthCode] = useState(""); // authCode 상태 추가 (문자열로 초기화)
  const [authTime, setAuthTime] = useState(60);
  const [authTimerId, setAuthTimerId] = useState(0);
  // const [selfAuth, setSelfAuth] = useState(false);
  // const [showAuth, setShowAuth] = useState(false); // setShowAuth 상태 추가
  const [resultHint, setResultHint] = useState(""); // setResultHint 상태 추가
  const [resultAuth, setResultAuth] = useState(""); // setResultHint 상태 추가
  const [showBeforeAuth, setShowBeforeAuth] = useState(true);
  const [showAfterAuth, setShowAfterAuth] = useState(false);

  const [visitPlacesTotal, setVisitPlacesTotal] = useState([]);
  const [visitPlaces, setVisitPlaces] = useState([]);
  // const [visitPlacesChild, setVisitPlacesChild] = useState([]);
  const [visitPorpose, setvisitPorpose] = useState([]);
  // const [selectedPlace, setSelectedPlace] = useState("");
  // NICE 본인인증 토큰값
  const [tokenData, setTokenData] = useState();
  // NICE 본인인증 결과값
  const [resultcode, setResultCode] = useState(null);
  // NICE 본인인증 방문객 전화 번호 결과값
  const [resultPhonenum, setResultPhonenum] = useState(null);

  // 본인인증 방식 타입 값
  const [authtype, setAuthType] = useState();

  const isEmpty = (str) => {
    return typeof str === "undefined" || str === null || str === "";
  };

  const startAuthTimer = () => {
    clearInterval(authTimerId); // 이전 타이머 해제

    setAuthTimerId(
      setInterval(() => {
        setAuthTime((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(authTimerId);
            // 상태 업데이트로 UI 변경
            setResultHint("인증시간이 만료되었습니다. 다시 인증해주세요.");
            setShowAfterAuth(false);
            setShowBeforeAuth(true);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000)
    );
  };

  const handleSendAuth = () => {
    if (isEmpty(tel)) {
      alert("연락처를 입력해주세요.");
      return;
    }

    // 4자리 랜덤 숫자 생성
    const randomAuthNumber = Math.floor(1000 + Math.random() * 9000);
    setTest(randomAuthNumber);
    // console.log(test);
    // AJAX 호출 대신 fetch 또는 axios 등을 사용하세요.
    // 이 코드는 AJAX 호출을 대체하는 것이 아닙니다.
    // 아래의 URL 및 데이터는 실제로 사용하는 서버에 맞게 수정해야 합니다.
    fetch("/api/Message/send-auth-code", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({ tel: tel, authCode: randomAuthNumber.toString() }), // 휴대폰 번호를 문자열로 서버에 전달
    })
      .then((response) => response.json())
      .then((data) => {
        switch (data.result) {
          case 0:
            // 상태 업데이트로 UI 변경
            setShowAfterAuth(true); // 인증번호 입력란 표시
            setShowBeforeAuth(false); // 본인인증 버튼 숨김
            setAuthTime(60);
            setResultHint("");
            setResultAuth("");
            startAuthTimer(null);
            setAuthCode(randomAuthNumber.toString());
            break;
          case 1:
            alert(data.message);
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  //확인버튼
  const handleAuthCheck = () => {
    var checkInputValue = document.getElementById("authCode").value;
    //console.log(checkInputValue, test.toString())

    if (checkInputValue === test.toString()) {
      // console.log("문자 인증 값과 작성한 인증값이 동일함");
      setResultAuth("인증되었습니다.");
      setAgreeState(true); // 본인인증 성공 시 agreeState를 true로 설정
    } else if (agreeState === false) {
      // console.log("문자 인증 값과 작성한 인증값이 동일하지않음");
      alert("인증번호가 다릅니다.");
      // 상태 업데이트로 UI 변경
      setResultAuth("다시 시도해주세요.");
      setShowBeforeAuth(true);
      setAgreeState(false); // 본인인증 실패 시 agreeState를 false로 설정
    }
    setShowAfterAuth(false); // authTime 숨기기
  };


  const PlaceToVisitApiUrl = `/api/PlaceToVisit?visitSiteIndex=${queryvisitSiteIndex}`;
  const PurposeOfVisitApiUrl = `/api/PurposeOfVisit?visitSiteIndex=${queryvisitSiteIndex}`;

  // NICE 토큰값 GET 요청을 보내는 함수
  const NiceTokenData = () => {
    fetch("/api/Nice")
      .then((res) => {
        if (!res.ok) {
          throw new Error("NICE API 호출 중 오류 발생");
        }
        return res.json();
      })
      .then((data) => {
        // 호출 성공
        // console.log("API 호출 결과:", data);
        setTokenData(data);
      });
  };

  const NiceButtonClick = (event) => {
    event.preventDefault(); // 기본 폼 제출 동작 방지
    if (tokenData) {
      try {
        const form = document.forms.niceForm;

        form.token_version_id.value = tokenData.tokenVersionId;
        form.enc_data.value = tokenData.cryptoData;
        form.integrity_value.value = tokenData.integrityValue;

        window.open("", "niceForm", "width=500,height=600");
        form.target = "niceForm";

        form.submit();
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      console.log("값없음");
    }
  };

  /* NICE 인증 결과값 받기 */
  /* 결과값은 NiceResult.jsx(65 line) 에서 넘겨주고있음 */
  useEffect(() => {
    function handleMessage(event) {
      // 원본을 확인하여 안전한 소스에서 오는 메시지만 처리
      // console.log(event.origin);
      // console.log(event.data);
      // console.log("전달받은 resultcode :", event.data.resultcode); // resultcode 값을 출력
      // console.log("전달받은 mobileno :", event.data.mobileNo); // mobileNo 값을 출력

      setResultPhonenum(event.data.mobileNo);

      console.log("전달받은 mobileNo :", event.data.mobileNo); // mobileNo 값

      if (event.data.mobileNo) {
        const nicebtn = document.getElementById('nice-result-btn');
        nicebtn.innerText = "본인인증 완료"
        nicebtn.style.backgroundColor = "#89B24A"
      }

      //if (event.origin !== 'https://visitor.schoolvisit.co.kr/niceresult') return;
    }

    window.addEventListener("message", handleMessage);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // PlaceToVisit GET 요청을 보내는 함수
  const fetchData = () => {
    fetch(PlaceToVisitApiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("API 호출 중 오류 발생");
        }
        return response.json();
      })
      .then((data) => {
        // API 호출 결과를 처리하는 로직 작성
        setVisitPlacesTotal(data.placeToVisits);
        const parent = data.placeToVisits.filter(
          (item) => item.parentIndex === -1
        ).sort((a, b) => parseInt(a?.itemOrder) - parseInt(b?.itemOrder) || 1)
        setVisitPlaces(parent);
        console.log(visitPlaces) 
      })
      .catch((error) => {
        console.error("API 요청 중 오류 발생:", error);
      });
  };

  let visitPlacesChild;
  if (watch("visitPlace") !== "선택해주세요") {
    const temp = visitPlacesTotal?.find(
      (item) => item.title === watch("visitPlace")
    );

    visitPlacesChild = visitPlacesTotal?.filter(
      (item) => item.parentIndex === temp?.placeToVisitIndex
    ).sort((a, b) => parseInt(a?.itemOrder) - parseInt(b?.itemOrder) || 1)
  }

  /* 본인인증 Type 가져오기 */
  const AuthTypeChck = () => {
    // API 호출
    fetch(`/api/VisitSite/${queryvisitSiteIndex}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // console.log("본인인증 타입값:", data.visitSite.visitorAuthType);
        setAuthType(data.visitSite.visitorAuthType);
      })
      .catch((error) => {
        console.error(
          "There was a problem with the fetch operation:",
          error.message
        );
      });
  };

  //PurposeOfVisit GET 요청 함수
  const PurposeOfVisitData = () => {
    fetch(PurposeOfVisitApiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("API 호출 중 오류 발생");
        }
        return response.json();
      })
      .then((data) => {
        // API 호출 결과를 처리하는 로직 작성
        // console.log("API 호출 결과:", data);
        setvisitPorpose(data.purposeOfVisits.sort((a, b) => parseInt(a?.itemOrder) - parseInt(b?.itemOrder) || 1));
        console.log(data.purposeOfVisits)
      }) 
      .catch((error) => {
        console.error("API 요청 중 오류 발생:", error);
      });
  };

  useEffect(() => {
    fetchData();
    PurposeOfVisitData();
    NiceTokenData();
    AuthTypeChck();
  }, []);

  const { data: VisitSiteName } = useQuery(
    ["VisitSiteName", queryvisitSiteIndex],
    apiGetVisitSiteName
  );

  const schoolName = VisitSiteName?.visitSite?.name;

  return (
    <div className="wrap">
      {/* 헤더 */}
      <Header pageTitle={`${schoolName} 방문예약`} pageSubTitle={""} />
      <div className="reservation-all">
        <form className="joinForm" onSubmit={handleSubmit(onSubmit)}>

          <div className="refusal-con">

            {/* 반려사유 */}
            <div className="refusal-titleAll">
              <div className="refusal-icon">
                <img src={Refusal} alt="반려사유" />
              </div>
              <div className="refusal-title">
                <p>반려사유</p>
              </div>
            </div>

            <div className="inputCon">
              <label className="labelClass">
                반려사유
              </label>
              <textarea></textarea>
            </div>

          </div>

          <div className="reservation-con">
            {/* 방문객 정보 */}
            <div className="reservation-titleAll">
              <div className="reservation-icon">
                <img src={Visitor} alt="방문객 정보" />
              </div>
              <div className="reservation-title">
                <p>방문객 정보</p>
              </div>
            </div>

            {/* 방문객명 */}
            <div className="inputCon">
              <label className="labelClass">
                방문객명<span>*</span>
              </label>
              <input
                readOnly={resultcode === "0000" ? true : false}
                className="inputClass essential"
                {...register("name", {
                  required: "이름을 입력해 주세요",
                  minLength: {
                    value: 2,
                    message: "방문객명은 2자 이상이어야 합니다.",
                  },
                  pattern: {
                    value: /^[A-Za-z가-힣]+$/, // 영문자와 숫자만 허용
                    message: "정확한 이름을 작성해주세요.", // 유효성 검사 실패 시 표시될 메시지
                  },
                })}
                placeholder="방문객명을 입력해 주세요"
                //defaultValue={info?.sessionName}
                autoComplete="nope" // 자동완성 방지
              />
            </div>
            <div className="errors-message">
              <div className=""></div>
              <div style={{ color: "tomato" }}>{errors?.name?.message} </div>
            </div>

            {/* 인증수단 
            <div className="inputCon">
              <label className="labelClass">
                {authtype === 1 ? "본인인증" : "전화번호 인증"}
                <span>*</span>
              </label>
              <div className="inputCon-all">
                <div className="inputCon-flex">
                  {authtype === 0 && (
                    <input
                      className="inputClass essential"
                      {...register("tel", {
                        required: "전화번호 인증은 필수 항목입니다.",
                        pattern: {
                          value: /^\d{10}$|^\d{11}$/,
                          message:
                            "전화번호는 10자 또는 11자이어야 합니다.(-제외)",
                        },
                      })}
                      type="text"
                      placeholder="전화번호를 입력해 주세요(-제외)"
                      value={tel}
                      onChange={(e) => setTel(e.target.value)} // 입력 값이 변경될 때 tel 상태 업데이트
                    />
                  )}
                  본인인증 하기
                  <div
                    id="before_auth"
                    style={{ display: showBeforeAuth ? "block" : "none" }}
                  >
                    {authtype === 0 && (
                      <button
                        className="btn-ctf"
                        type="button"
                        onClick={handleSendAuth}
                      >
                        본인인증
                      </button>
                    )}
                    {authtype === 1 && (
                      <button className="btn-ctf" onClick={NiceButtonClick} id="nice-result-btn">
                        본인인증
                      </button>
                    )}
                  </div>
                </div>

                <div className="result-all">
                  본인인증 확인 결과 
                  <p id="result_hint" className="hint-ctf">
                    {authTime <= 0 ? (
                      <span>{resultHint}</span>
                    ) : (
                      <>
                        <span
                          style={{
                            display:
                              showAfterAuth && authTime >= 0 ? "block" : "none",
                          }}
                        >
                          {authTime}
                        </span>
                        <span
                          style={{
                            display: authTime <= 0 ? "none" : "block",
                          }}
                        >
                          {resultAuth}
                        </span>
                      </>
                    )}
                  </p>

                  <!--본인인증 번호 입력 및 결과-->
                  <div
                    id="after_auth"
                    style={{ display: showAfterAuth ? "block" : "none" }}
                  >
                    <!--본인인증 정보 입력--> 
                    <input
                      className="input-ctf"
                      type="text"
                      id="authCode"
                      placeholder="인증번호 입력"
                      maxLength="4"
                      inputMode="numeric"
                      onChange={(e) => setAuthCode(e.target.value)}
                    />
                    <button
                      className="btn-ctf-ok btn-ctf"
                      type="button"
                      onClick={handleAuthCheck}
                    >
                      확인
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="errors-message">
              <div className=""></div>
              <div style={{ color: "tomato" }}>{errors?.tel?.message} </div>
            </div>

            */}
            {/* 차량번호 */}
            <div className="inputCon">
              <label className="labelClass">차량번호</label>
              <input
                className="inputClass"
                {...register("carNum")}
                placeholder="차량번호 뒤 4자리를 입력해 주세요"
                maxLength="4"
                type="text"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
                }}
                autoComplete="new-password" // 자동완성 방지
              />
            </div>

            {/* 메모 */}
            <div className="inputCon">
              <label className="labelClass" htmlFor="text">
                메모
              </label>
              <textarea
                className="inputClass3"
                {...register("memo")}
                rows="5"
                maxLength="100"
                autoComplete="new-password" // 자동완성 방지
                placeholder="최대 100자까지 입력 가능합니다."
              />
            </div>
          </div>

          {/* 방문정보 */}
          <div className="reservation-con">
            <div className="reservation-titleAll">
              <div className="reservation-icon">
                <img src={Pin} alt="방문장소" />
              </div>
              <div className="reservation-title">
                <p>방문장소 정보</p>
              </div>
            </div>

            {/* 방문지 부모 */}
            <div className="inputCon">
              <label className="labelClass" htmlFor="visitPlace">
                방문지<span>*</span>
              </label>
              <select
                id="visitPlace"
                className="inputClass essential"
                {...register("visitPlace", {
                  required: "방문지를 선택해 주세요.",
                  validate: value => value !== "" || "방문지를 선택해 주세요."
                })}
              >
                <option className="option-first" value="" >선택해주세요</option>
                {visitPlaces.map((place) => (
                  <option key={place.placeToVisitIndex} value={place.title}>
                    {place.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="errors-message">
              <div className=""></div>
              <div style={{ color: "tomato" }}>
                {errors?.visitPlace?.message}{" "}
              </div>
            </div>

            {/* 방문지 자식 */}
            {visitPlacesChild && visitPlacesChild.length > 0 ? (
              <div className="inputCon">
                <label className="labelClass" htmlFor="visitPlace">
                  방문지 상세<span>*</span>
                </label>
                <select
                  id="visitPlace"
                  className="inputClass essential"
                  {...register("visitPlace2", {
                    required: "방문지 상세 내용을 선택해 주세요.",
                  })}
                >

                  <option className="option-first" value="">선택해주세요</option>

                  {visitPlacesChild?.map((place, index) => (
                    <option key={index} value={place.title}>
                      {place.title}
                    </option>
                  ))}
                </select>

                <div className="errors-message">
                  <div className=""></div>
                  <div style={{ color: "tomato" }}>{errors?.visitPlace2?.message} </div>
                </div>


              </div>
            ) : null}

            <div className="inputCon">
              <label className="labelClass" htmlFor="DropDown">
                방문 목적<span>*</span>
              </label>
              <select
                className="inputClass essential"
                {...register("visitPurpose", {
                  required: "방문목적을 선택해 주세요.",
                  minLength: {
                    value: 1,
                    message: "방문목적을 선택해 주세요.",
                  },
                })}
              >
                <option value="">선택해주세요</option>
                {visitPorpose.map((purpose) => (
                  <option
                    key={purpose.purposeOfVisitIndex}
                    value={purpose.title}
                  >
                    {purpose.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="errors-message">
              <div className=""></div>
              <div style={{ color: "tomato" }}>
                {errors?.visitPurpose?.message}{" "}
              </div>
            </div>

            <div className="inputCon">
              <label
                htmlFor="visitDate"
                className="labelClass"
                type="datetime-local"
              >
                방문 일자<span>*</span>
              </label>
              <input
                type="date"
                className="date-time"
                {...register("date", {
                  required: "방문 날짜를 선택해 주세요."
                })}
              />
            </div>

            <div className="errors-message">
              <div className=""></div>
              <div style={{ color: "tomato" }}>{errors?.date?.message} </div>
            </div>

            <div className="inputCon">
              <label className="labelClass" type="datetime-local" >
                방문 시간<span>*</span>
              </label>
              <input
                className="date-time"
                type="time"
                {...register("time", {
                  required: "방문 시간을 선택해 주세요."
                })}
              />
            </div>

            <div className="errors-message">
              <div className=""></div>
              <div style={{ color: "tomato" }}>{errors?.time?.message} </div>
            </div>

          </div>

          {/* 비밀번호 */}
          {/*<div className="reservation-con">
            <div className="reservation-titleAll">
              <div className="reservation-icon">
                <img src={Lock} alt="비밀번호" />
              </div>
              <div className="reservation-title">
                <p>예약 확인 비밀번호</p>
              </div>
            </div>
            <div className="inputCon">
              <label className="labelClass">
                비밀번호<span>*</span>
              </label>
              <input
                className="inputClass essential"
                {...register("firstPassword", {
                  required: "비밀번호는 필수입력 입니다.",
                  pattern: {
                    value: /^\d{4}$/,
                    message: "숫자 4자리만 입력가능",
                  },
                })}
                type="password"
                placeholder="본인확인을 위한 비밀번호를 입력해주세요"
                autoComplete="new-password" // 자동완성 방지
                maxLength="4"
              />
            </div>
            <div className="errors-message">
              <div className=""></div>
              <div style={{ color: "tomato" }}>
                {errors?.firstPassword?.message}{" "}
              </div>
            </div>

            <div className="inputCon">
              <label className="labelClass">
                비밀번호 확인<span>*</span>
              </label>
              <input
                className="inputClass essential"
                {...register("lastPassword", {
                  required: "비밀번호 확인은 필수입력 입니다.",
                })}
                type="password"
                placeholder="입력하신 비밀번호를 한 번 더 입력해 주세요"
                autoComplete="new-password" // 자동완성 방지
                maxLength="4"
              />
            </div>
            <div className="errors-message">
              <div className=""></div>
              {watch("firstPassword") !== watch("lastPassword") && (
                <div style={{ color: "tomato" }}>
                  비밀번호는 같아야 합니다.{" "}
                </div>
              )}
            </div>
          </div>
*/}

          {/* 버튼 */}
          <div className="btn-all ">
            <div className="btn-blue">
              <button>신청하기</button>
            </div>
          </div>
        </form>
      </div>

      {/* 개인정보처리방침 */}
      <div>
        <Footer />
      </div>
      <form
        name="niceForm"
        method="post"
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb"
      >
        <input type="hidden" id="m" name="m" value="service" />
        <input
          type="hidden"
          id="token_version_id"
          name="token_version_id"
          value=""
        />
        <input type="hidden" id="enc_data" name="enc_data" />
        <input type="hidden" id="integrity_value" name="integrity_value" />
      </form>
    </div>
  );
}
