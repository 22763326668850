import React from "react";

export default function Button({ btn, btnIcon, disabled = false }) {
  return (
    <div>
      <button type="submit" disabled={disabled}>
        {btn}
        <img alt="button" src={btnIcon} />
      </button>
    </div>
  );
}
