import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Button from "../components/Button";

import "../components/Layout.css";
import "./Search.css";
import "../components/Modal.css";

import QRCode from "qrcode"; // qrcode 라이브러리 import
import Home from "../assets/png/home.png";

export default function Search() {
  const [info, setInfo] = useState();
  useEffect(() => {
    const temp = localStorage.getItem("schoolinfo");
    const tempData = JSON.parse(temp);
    setInfo(tempData);
  }, []);

  // QR 이미지 생성과 위도 경도 생성
  const [qrCodeImage, setQRCodeImage] = useState("");
  // const [latitude, setLatitude] = useState(null);
  // const [longitude, setLongitude] = useState(null);

  const [userdata, setUserData] = useState([]);

  // const [dataItem, setDataItem] = useState(); // dataItem 상태 변수 추가

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(); // 선택한 사용자 데이터

  const location = useLocation();
  // const modal = searchParams.get("modal");

  //  const queryParams = new URLSearchParams(location.search);
  const searchParams = new URLSearchParams(location.search);
  const getModal = searchParams.get("modal");
  const getCode = searchParams.get("code");

  // URL 쿼리 파라미터에서 'visitsite' 값을 추출
  const queryParams = new URLSearchParams(location.search);
  const queryvisitSiteIndex = queryParams.get('visitsite'); // 쿼리 파라미터

  const visitSiteIndex = sessionStorage.getItem('VisitSite') // 세션에 저장된 VisitSite값


  const [visitorName, setVisitorName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");

  const handleVisitorNameChange = (e) => {
    setVisitorName(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const queryShowModal = () => {
    // API 요청을 보내는 fetch 코드
    fetch(`/api/VisitReservation/code/${getCode}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error("API 요청에 실패했습니다.");
          alert("API 요청에 실패했습니다.");
          throw new Error("API 요청에 실패했습니다.");
        }
      })
      .then((data) => {
        console.log(data);
        // API 응답 데이터를 사용하여 모달에 표시할 정보를 설정합니다.
        const modalData = {
          qrCodeImage: data.code, // Use the code to generate the QR code image
          name: data.name,
          placeToVisit: data.placeToVisit,
          purposeOfVisit: data.purposeOfVisit,
          reservationDate: data.reservationDate,
        };

        // Call generateQRCode to set the QR code image in the state
        generateQRCode(modalData.qrCodeImage);

        // 모달 데이터를 설정하고 모달을 엽니다.
        setSelectedUserData(modalData);
        setModalOpen(true);
      })
      .catch((error) => {
        console.error("API 요청 오류:", error);
      });
  };

  const handleSearch = () => {

    const trimmedVisitorName = visitorName.replace(/\s+/g, '')
    const trimmedPhoneNumber = phoneNumber.replace(/\s+/g, '')
    const trimmedPassword = password.replace(/\s+/g, '')

    const queryParams = new URLSearchParams({
      visitSiteIndex: queryvisitSiteIndex,
      name: trimmedVisitorName,
      tel: trimmedPhoneNumber,
      password: trimmedPassword,
      document,
    });

    // 첫 번째 검색 시 userdata 초기화
    setUserData([]);

    fetch(`/api/VisitReservation/search-with-visitor?${queryParams.toString()}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error("검색 요청에 실패했습니다.");
          alert("검색 요청에 실패했습니다.");
        }
      })
      .then((data) => {
        console.log("검색 결과 데이터:", data);
        setUserData(data.vitReservations);

        // 검색 결과 데이터를 신청일시(regDate)를 기준으로 내림차순 정렬합니다.
        const sortedData = data.vitReservations.sort((a, b) => {
          return new Date(b.regDate).getTime() - new Date(a.regDate).getTime();
        });

        setUserData(sortedData);
        const itemDivs = document.querySelectorAll(".item");
        itemDivs.forEach((itemDiv, index) => {
          itemDiv.style.display = "grid";
          console.log(itemDiv);
          const dataItem = data.vitReservations[index];

          const placeToVisitElement = itemDiv.querySelector(".placeToVisit");
          const nameElement = itemDiv.querySelector(".name");
          const purposeOfVisitElement =
            itemDiv.querySelector(".purposeOfVisit");

          placeToVisitElement.textContent = dataItem.placeToVisit;
          nameElement.textContent = dataItem.name;

          purposeOfVisitElement.textContent = dataItem.purposeOfVisit;
        });
      })
      .catch((error) => {
        console.error("네트워크 오류 또는 처리 오류:", error);
      });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const generateQRCode = async (code) => {
    try {
      const qrCodeImage = await QRCode.toDataURL(code);
      setQRCodeImage(qrCodeImage); // Set the QR code image in the state
      console.log(qrCodeImage);
      return qrCodeImage;
    } catch (error) {
      console.error("QR 코드 생성 오류:", error);
      return ""; // 이미지 생성 실패 시 빈 문자열 반환
    }
  };

  const currentTimestamp = new Date().toLocaleString("ko-KR");

  // 사용자의 현재 위치 정보를 가져오는 함수
  // const getCurrentLocation = () => {
  //   if (navigator.geolocation) {
  //     const options = {
  //       enableHighAccuracy: true, // 높은 정확성 요청
  //       maximumAge: 0, // 캐시된 위치 정보를 사용하지 않음
  //     };

  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setLatitude(position.coords.latitude);
  //         setLongitude(position.coords.longitude);
  //       },
  //       (error) => {
  //         console.error("위치 정보 가져오기 실패:", error);
  //       },
  //       options
  //     );
  //   } else {
  //     console.error("Geolocation API를 지원하지 않음");
  //   }
  // }; 

  const openModal = async (selectedUserData) => {
    const qrCodeImage1 = await generateQRCode(selectedUserData.code);
    setSelectedUserData(selectedUserData);
    setQRCodeImage(qrCodeImage1);
    // console.log(qrCodeImage);

    // 확인용 로그 추가
    // console.log(selectedUserData);

    // setDataItem(selectedUserData.reservationDate);
    setModalOpen(true);
  };

  useEffect(() => {
    /*
    getCurrentLocation();
    // 위치 정보가 변경될 때마다 QR 코드 업데이트
    if (latitude !== null && longitude !== null) {
      const locationData = `Latitude: ${latitude}, Longitude: ${longitude},Time:${currentTimestamp}`;
      generateQRCode(locationData);
    }*/
    if (getModal === "true" && getCode) {
      // modal=true 이고 code 값이 있는 경우 모달을 엽니다.
      queryShowModal(getCode);
    } else {
      // 그 외의 경우 모달을 닫습니다.
      setModalOpen(false);
    }
  }, [getModal, getCode]);

  const closeModal = () => {
    setSelectedUserData(null); // 선택한 사용자 데이터 초기화
    setModalOpen(false);
  };

  // console.log(new Date(Date.now()));

  const currentTime = new Date();

  return (
    <div className="wrap modal-wrap">
      <Header
        pageTitle={"방문신청 조회"}
        pageSubTitle={"개인정보를 입력하신 후 검색해 주세요."}
      />
      <div className="search-all">
        <div className="searchInput">
          <div className="inputCon2">
            <label className="labelClass2">방문객명</label>
            <input
              className="inputClass2"
              type="text"
              id="name"
              name="name"
              value={visitorName}
              onChange={handleVisitorNameChange}
              autoComplete="new-password" // 자동완성 방지
            />
          </div>
          <div className="inputCon2">
            <label className="labelClass2">전화번호</label>
            <input
              className="inputClass2"
              type="text"
              id="tel"
              name="tel"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              autoComplete="new-password" // 자동완성 방지
            />
          </div>
          <div className="inputCon2">
            <label className="labelClass2">비밀번호</label>
            <input
              className="inputClass2"
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="new-password" // 자동완성 방지
            />
          </div>
          <div className="btn-search">
            <button onClick={handleSearch}>검색</button>
          </div>
        </div>
        <div className="searchShow">
          <div className="item-title">
            <p>방문지</p>
            <p>방문객명</p>
            <p>방문예정일시</p>
            <p>방문목적</p>
            <p>신청일시</p>
            <p>상태</p>
            <p>예약취소</p>
            <p>방문증</p>
            <p>비고</p>
          </div>

          {userdata.map((dataItem, index) => (
            <div
              className="item"
              style={{
                color: currentTime > new Date(dataItem?.reservationDate) ? "lightgray" : "black",
              }}
              key={index}
            >
              <p className="placeToVisit">
                <span>방문지</span>
                <span>{dataItem.placeToVisit}</span>
              </p>
              <p className="name">
                <span>방문객명</span>
                <span>{dataItem.name}</span>
              </p>
              <p className="reservationDate">
                <span>방문예정일시</span>
                <span>{new Date(dataItem?.reservationDate).toISOString().replace("T", " ").replace("Z", "").slice(0, -7)}</span>
              </p>
              <p className="purposeOfVisit">
                <span>방문목적</span>
                <span>{dataItem.purposeOfVisit}</span>
              </p>
              <p className="regDate">
                <span>신청일시</span>
                <span>{new Date(dataItem.regDate).toISOString().replace("T", " ").replace("Z", "").slice(0, -7)}</span>
              </p>
              <p className="state">
                <span>상태</span>
                <span>
                  {(() => {
                    switch (dataItem.state) {
                      case 0:
                        return <p>대기중</p>;
                      case 1:
                        return <p>승인</p>;
                      case 2:
                        return <p>반려</p>;
                      case 3:
                        return <p>방문</p>;
                      case 4:
                        return <p>예약취소</p>;
                      case 5:
                        return <p>퇴실</p>;
                      default:
                        return;
                    }
                  })()}
                </span>
              </p>
              <p>
                {/* 예약취소 버튼 */}
                {dataItem.state === 0 || dataItem.state === 1 ? (
                  <Link to={`/cancel?index=${dataItem.code}&visitsite=${queryvisitSiteIndex}`}>
                    <button
                      disabled={currentTime > new Date(dataItem?.reservationDate)}
                      className="cancel"
                      style={{
                        background: currentTime > new Date(dataItem?.reservationDate) ? "lightgray" : "",
                        cursor: currentTime > new Date(dataItem?.reservationDate) ? "default" : "",
                      }}
                    >
                      취소하기
                    </button>
                  </Link>
                ) : (
                  <span></span>
                )}
              </p>
              <p>
                {/* 방문증 버튼 */}
                {dataItem.state === 1 && (
                  <button
                    disabled={currentTime > new Date(dataItem?.reservationDate)}
                    className="modal-btn"
                    style={{
                      background: currentTime > new Date(dataItem?.reservationDate) ? "lightgray" : "",
                      cursor: currentTime > new Date(dataItem?.reservationDate) ? "default" : "",
                    }}
                    onClick={() => openModal(dataItem)}
                  >
                    보기
                  </button>
                )}
              </p>
              <p className="stateReason">
                <span>비고</span>
                <span>{dataItem.stateReason}</span>
              </p>

            </div>
          ))}
        </div>

        <div className="parent-class">
          <Link to={`/home?visitsite=${queryvisitSiteIndex}`}>
            <div className="btn-gray home-btn link-button">
              <Button btn={"홈으로"} btnIcon={Home} />
            </div>
          </Link>
        </div>

      </div>
      {/* <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        selectedUserData={selectedUserData} // 선택한 사용자 데이터를 모달에 전달
        qrCodeImage={qrCodeImage}
        dataItem={dataItem}
        getCode={getCode}
        getModal={getModal}
      /> */}
      <div
        className="modal"
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div className="modal-content">
          <div className="modal-con-all">
            <div className="modal-title">
              <p>모바일 방문증</p>
            </div>
            <div className="modal-con">
              <div className="modal-QR">
                <img src={qrCodeImage} alt="QR" />
              </div>
              <div className="modal-name">
                <p>{selectedUserData && selectedUserData.name}</p>
              </div>
              <div className="modal-sub-con">
                <div className="modal-sub-con-title">
                  <p>방문지</p>
                  <p>방문목적</p>
                  <p>방문예약일시</p>
                </div>
                <div className="modal-sub-con-text">
                  <p>{selectedUserData && selectedUserData.placeToVisit}</p>
                  <p>{selectedUserData && selectedUserData.purposeOfVisit}</p>
                  <p>
                    {selectedUserData &&
                      new Date(selectedUserData?.reservationDate)
                        .toISOString()
                        .replace("T", " ")
                        .replace("Z", "")
                        .slice(0, -7)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-close">
            <button onClick={closeModal}>Χ</button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
