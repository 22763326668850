import React from "react";

export default function Step({ icon, title }) {
  return (
    <div className="step list1">
      <div className="step-circle">
        <img src={icon} alt="icon" />
      </div>
      <div className="step-list-title">
        <p>{title}</p>
      </div>
    </div>
  );
}
