import React, { useEffect } from 'react';
import Main from "./app/Main";

function App() {
  useEffect(() => {
    // URL 쿼리 파라미터에서 'visitsite' 값을 추출하고 세션에 저장
    const queryParams = new URLSearchParams(window.location.search);
    const VisitSite = queryParams.get('visitsite');
    if (VisitSite) {
      sessionStorage.setItem('VisitSite', VisitSite);
    }
  }, []);
  return (
    <div>
      <Main />
    </div>
  );
}

export default App;