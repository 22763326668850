import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import '../components/Button.css';
import '../components/Layout.css';
import '../app/Agree.css';
import { Link } from 'react-router-dom';
import Button from '../components/Button';
import Home from '../assets/png/home.png';
import Arrow from '../assets/png/arrow.png';
import { useForm } from 'react-hook-form';
import { useLocation } from "react-router-dom";


export default function Agree() {
  const location = useLocation();
  const visitSiteIndex = sessionStorage.getItem('VisitSite') // 세션에 저장된 VisitSite값 

  const queryParams = new URLSearchParams(location.search);
  const queryvisitSiteIndex = queryParams.get('visitsite'); // 쿼리 파라미터 추출 

  const apiUrl = `/api/Agreement?visitSiteIndex=${queryvisitSiteIndex}`;

  const [checkList, setCheckList] = useState([]);
  const { register } = useForm();
  const [agreements, setAgreements] = useState([]);

  useEffect(() => {
    // 세션에서 페이지가 이미 새로고침되었는지 확인
    const isPageReloaded2 = sessionStorage.getItem("isPageReloadedAgree");

    if (!isPageReloaded2) {
      // 세션에 새로고침 플래그를 설정
      sessionStorage.setItem("isPageReloadedAgree", "true");

      // 페이지 새로고침
      window.location.reload();
      return; // 이후 로직을 방지하기 위해 return
    }

    fetch(apiUrl)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error('API 요청에 실패했습니다.');
          throw new Error('API 요청에 실패했습니다.');
        }
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setAgreements(data);

        } else if (typeof data === 'object' && Array.isArray(data.agreements)) {
          setAgreements(data.agreements);
          console.log(data.agreements)
        } else {
          console.error('API 응답 데이터가 배열이 아닙니다.');
          throw new Error('API 응답 데이터가 배열이 아닙니다.');
        }
      })
      .catch((error) => {
        console.error('네트워크 오류 또는 처리 오류:', error);
      });
  }, [apiUrl]);

  const handleCheck = (e) => {
    const { name, checked } = e.target; 
    if (checked) {
      setCheckList([...checkList, name]);
    } else {
      setCheckList(checkList.filter((item) => item !== name));
    }
  };

  const handleVisitRequest = () => {
    // 필수 항목(isMust가 true인 항목)만 추출
    const mustAgreements = agreements.filter(agreement => agreement.isMust);
  
    // 필수 항목들이 모두 체크되었는지 확인
    const allMustAgreed = mustAgreements.every(agreement => checkList.includes(`agree${agreements.indexOf(agreement)}`));
  
    if (!allMustAgreed) {
      alert('모든 필수 동의 항목에 동의해야 합니다.');
    } else {
      window.location.href = `/reservation?visitsite=${queryvisitSiteIndex}`;
    }
  };

  const renderAgreements = () => {
    return agreements.map((agreement, index) => (
      <div className="agree" key={agreement.agreementIndex}>
        <div className="agree-text">
          <p className="agree-title">
            {agreement.title}
            {agreement.isMust ? <span>(필수)</span> : ''}
          </p>
        </div>
        <div className="agree-con">{agreement.contents}</div>
        <div className="agree-checkBox">
          <input
            id={`agree${index}`}
            type="checkbox"
            {...register(`agree${index}`)}
            onChange={handleCheck}
          />
          <label className="labelText" htmlFor={`agree${index}`}>
            동의합니다.
          </label>
        </div>
      </div>
    ));
  };

  return (
    <div className="wrap">
      <Header
        pageTitle={'개인정보동의'}
        pageSubTitle={'개인정보 동의 후 방문 예약이 가능합니다.'}
      />
      <div className="agree-all">
        {renderAgreements()}
        <div className="btn-all">
          <Link to={`/home?visitsite=${queryvisitSiteIndex}`}>
            <div className="btn-gray">
              <Button btn={'홈으로'} btnIcon={Home} />
            </div>
          </Link>
          <div className="btn-blue" onClick={handleVisitRequest}>
            <Button btn={'다음'} btnIcon={Arrow} />
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}
