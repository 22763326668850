// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 버튼 */
.btn-all {
      padding-top: 5%;
      margin: 0 auto;
      width: 35%;
      display: flex;
      justify-content:  space-around;
}

button {
      width: 150px;
      height: 50px;
      border-radius: 5px;
      border: none;
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;
}

.btn-blue button {
      background-color: #00398F;
}

.btn-gray button {
      background-color: #A8A8A8;
}

.main-btn-gray button {
      background-color: #6192dd;
}

.btn-search button {
      background-color: #0066FF;
      width: 60px;
      height: 30px;
      font-size: 16px;
      font-weight: 400;
}

button img {
      padding-left: 8%;
}

@media (max-width: 768px) { 
      /* 버튼 */
      .btn-all {
            padding-top: 10%;
            width: 85%;
      }

      button {
            width: 135px;
            height: 45px;
            font-size: 16px;
      }
}`, "",{"version":3,"sources":["webpack://./src/components/Button.css"],"names":[],"mappings":"AAAA,OAAO;AACP;MACM,eAAe;MACf,cAAc;MACd,UAAU;MACV,aAAa;MACb,8BAA8B;AACpC;;AAEA;MACM,YAAY;MACZ,YAAY;MACZ,kBAAkB;MAClB,YAAY;MACZ,WAAW;MACX,eAAe;MACf,gBAAgB;MAChB,eAAe;AACrB;;AAEA;MACM,yBAAyB;AAC/B;;AAEA;MACM,yBAAyB;AAC/B;;AAEA;MACM,yBAAyB;AAC/B;;AAEA;MACM,yBAAyB;MACzB,WAAW;MACX,YAAY;MACZ,eAAe;MACf,gBAAgB;AACtB;;AAEA;MACM,gBAAgB;AACtB;;AAEA;MACM,OAAO;MACP;YACM,gBAAgB;YAChB,UAAU;MAChB;;MAEA;YACM,YAAY;YACZ,YAAY;YACZ,eAAe;MACrB;AACN","sourcesContent":["/* 버튼 */\r\n.btn-all {\r\n      padding-top: 5%;\r\n      margin: 0 auto;\r\n      width: 35%;\r\n      display: flex;\r\n      justify-content:  space-around;\r\n}\r\n\r\nbutton {\r\n      width: 150px;\r\n      height: 50px;\r\n      border-radius: 5px;\r\n      border: none;\r\n      color: #fff;\r\n      font-size: 18px;\r\n      font-weight: 400;\r\n      cursor: pointer;\r\n}\r\n\r\n.btn-blue button {\r\n      background-color: #00398F;\r\n}\r\n\r\n.btn-gray button {\r\n      background-color: #A8A8A8;\r\n}\r\n\r\n.main-btn-gray button {\r\n      background-color: #6192dd;\r\n}\r\n\r\n.btn-search button {\r\n      background-color: #0066FF;\r\n      width: 60px;\r\n      height: 30px;\r\n      font-size: 16px;\r\n      font-weight: 400;\r\n}\r\n\r\nbutton img {\r\n      padding-left: 8%;\r\n}\r\n\r\n@media (max-width: 768px) { \r\n      /* 버튼 */\r\n      .btn-all {\r\n            padding-top: 10%;\r\n            width: 85%;\r\n      }\r\n\r\n      button {\r\n            width: 135px;\r\n            height: 45px;\r\n            font-size: 16px;\r\n      }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
