import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import MainLogo from "../assets/png/__high-logo.png";
import Join from "../assets/png/join.png";
import Check from "../assets/png/check.png";
import Nametac from "../assets/png/nametac.png";
import Handshake from "../assets/png/handshake.png";
import Arrow from "../assets/png/arrow.png";

import "../app/Main.css";
import "../components/Button.css";
import "../components/Layout.css";

import Step from "../components/Step";
import Button from "../components/Button";
import Footer from "../components/Footer";

import { Link } from "react-router-dom";

export default function Main() {
  const [imgUrl, setImgUrl] = useState("'");
  const location = useLocation();

  // URL 쿼리 파라미터에서 'visitsite' 값을 추출
  const queryParams = new URLSearchParams(location.search);
  const queryvisitSiteIndex = queryParams.get('visitsite'); // 쿼리 파라미터가 없는 경우 기본값으로 '0'을 사용

  useEffect(() => {
    
    window.location.href = 'https://visitor-reservation.schoolvisit.co.kr/?visitsiteindex=AZlGM2jeGOswQcRtY0Q0Yg==';

    // 세션에서 페이지가 이미 새로고침되었는지 확인
    const isPageReloaded = sessionStorage.getItem("isPageReloaded");

    if (!isPageReloaded) {
      // 세션에 새로고침 플래그를 설정
      sessionStorage.setItem("isPageReloaded", "true");

      // 페이지 새로고침
      window.location.reload();
      return; // 이후 로직을 방지하기 위해 return
    }

    // 세션에서 VisitSite 값을 가져옴
    const sessionVisitSiteIndex = sessionStorage.getItem('VisitSite');

    // API 요청을 위한 URL 구성
    const VisitApiUrl = `/api/VisitSite/${queryvisitSiteIndex}`;

    const getVisitIndex = () => {
      fetch(VisitApiUrl)
        .then(response => response.json())
        .then(data => setImgUrl(data?.visitSite?.titleImageUrl))
        .catch(error => console.error("API 요청 중 오류 발생:", error));
    };

    if (queryvisitSiteIndex) {
      getVisitIndex();
    }



  }, [location.search]);

  return (
    <div className="wrap">
      <div className="main-all">
        <div className="main-logo">
          <img src={imgUrl} alt="main-logo" />
        </div>
        {/* 방문절차 */}
        <div className="step-all">
          <div className="step-title">
            <p>방문절차</p>
          </div>
          <div className="step-line">{/* 라인 */}</div>
          <div className="step-list">
            {/* 방문신청 */}
            <Step icon={Join} title={"방문신청"} />
            {/* 내부승인 */}
            <Step icon={Check} title={"내부승인"} />
            {/* 방문수속 */}
            <Step icon={Nametac} title={"방문수속"} />
            {/* 방문 */}
            <Step icon={Handshake} title={"방문"} />
          </div>
        </div>
        {/* 버튼 */}
        <div className="btn-all">
          <Link to={`/agree?visitsite=${queryvisitSiteIndex}`}>
            <div className="btn-blue">
              <Button btn={"방문신청"} btnIcon={Arrow} />
            </div>
          </Link>
          <Link to={`/search?visitsite=${queryvisitSiteIndex}`}>
            <div className="btn-gray main-btn-gray">
              <Button btn={"방문신청 조회"} btnIcon={Arrow} />
            </div>
          </Link>
        </div>
      </div>
      {/* 개인정보처리방침 */}
      <div>
        <Footer />
      </div>
    </div>
  );
}
