export async function apiGetVisitSiteName({ queryKey }) {
  const visitSiteIndex = queryKey[1];

  return await fetch(`/api/VisitSite/${visitSiteIndex}`, {
    method: "GET",
    headers: {
      accept: "*",
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then((res) => res.json());
}
