import React, { useRef, useEffect } from 'react';
import Button from '../components/Button';
import Footer from '../components/Footer';
import Header from '../components/Header'
import CancelImg from "../assets/png/cancel.png";
import ArrowBack from "../assets/png/arrow_back.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";


export default function Cancel() {
      const {
            handleSubmit,
            register,
            formState: { errors },
            watch,
      } = useForm({ mode: "onChange" });
      const navigate = useNavigate();
      const onSubmit = (data) => {
      };

      const location = useLocation()

      const params = new URLSearchParams(location.search)
      const userCode = params.get("index")
      const queryvisitSiteIndex = params.get('visitsite');



      const canceltextRef = useRef(); // Ref 생성

      const handleClick = () => {

            // Ref를 통한 취소 사유 데이터 요소에 접근
            if (!canceltextRef.current?.value) {
                  alert("취소사유를 상세히 입력해 주세요");
                  alert(userCode)
                  alert(queryvisitSiteIndex)
                  return;
            }
            fetch(`/api/VisitReservation/cancel`, {
                  method: "PUT",
                  headers: {
                        "accept": "*/*",
                        "Content-Type": "application/json"
                  },
                  body: JSON.stringify({
                        code: userCode,
                        cancelMessage: canceltextRef.current.value // textarea의 value 사용
                  })
            }).then(res => res.json()).
                  then(data => {
                        alert("방문 취소요청을 완료하였습니다.")
                        navigate(`/search?visitsite=${queryvisitSiteIndex}`);

                  }).
                  then(error => console.log(error))
      }


      return (
            <div className="wrap">
                  {/* 헤더 */}
                  <Header
                        pageTitle={"방문예약 취소"}
                        pageSubTitle={"방문예약 취소를 위해 아래 항목을 입력해 주세요."}
                  />
                  <div className="reservation-all">
                        <form className="joinForm" onSubmit={handleSubmit(onSubmit)}>
                              <div className="reservation-con">
                                    <div className="reservation-titleAll">
                                          <div className="reservation-icon">
                                                <img src={CancelImg} alt="취소사유" />
                                          </div>
                                          <div className="reservation-title">
                                                <p>취소사유</p>
                                          </div>
                                    </div>
                                    <div className="inputCon">
                                          <label className="labelClass" for="text">취소사유<span>*</span></label>
                                          <textarea
                                                ref={canceltextRef} // Ref 연결
                                                className="inputClass3"
                                                rows="6"
                                                placeholder=""
                                          />
                                    </div>

                              </div>
                              {/* 버튼 */}
                              <div className="btn-all ">
                                    <Link to={`/search?visitsite=${queryvisitSiteIndex}`}>
                                          <div className="btn-gray">
                                                <Button btn={"이전"} btnIcon={ArrowBack} />
                                          </div>
                                    </Link>
                                    <div className="btn-blue">
                                          <button onClick={handleClick} className="cancelBtn">취소하기</button>
                                    </div>
                              </div>
                        </form>
                  </div>
                  {/* 개인정보처리방침 */}
                  <div>
                        <Footer />
                  </div>
            </div>
      );
}
