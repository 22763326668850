import React from 'react'

export default function Footer() {
  return (
    <div>
            <footer className='footer-all'>
                  <p className='footer-title'>개인정보처리방침</p>
                  <p className='footer-copy'>©2023. cnp Inc. All rights reserved.</p>
            </footer>
    </div>
  )
}
